import "./src/styles/global.scss";
import { scroller } from "react-scroll";

function scrollToSection(sectionId) {
  scroller.scrollTo(sectionId, {
    smooth: true,
    duration: 1000,
  });
}

export function shouldUpdateScroll({
  routerProps: { location },
  getSavedScrollPosition,
}) {
  if (location.href.indexOf("#") > -1) {
    var sectionId = location.href.substring(
      location.href.indexOf("#") + 1,
      location.href.length
    );
    console.log(sectionId);
    if (sectionId && sectionId.length > 0) {
      window.location.hash = "";
      setTimeout(scrollToSection(sectionId), 1000);
      return false;
    }
  }
  return true;
}
